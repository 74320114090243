@use "sass:math";

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin font($min-font-size, $max-font-size) {
  $u1: unit(420px);
  $u2: unit(1600px);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: 420px) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - 420px) / #{strip-unit(1600px - 420px)}));
      }

      @media screen and (min-width: 1600px) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin lh($min-lh, $max-lh) {
  $u1: unit(420px);
  $u2: unit(1600px);
  $u3: unit($min-lh);
  $u4: unit($max-lh);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      line-height: $min-lh;

      @media screen and (min-width: 420px) {
        line-height: calc(#{$min-lh} + #{strip-unit($max-lh - $min-lh)} * ((100vw - 420px) / #{strip-unit(1600px - 420px)}));
      }

      @media screen and (min-width: 1600px) {
        line-height: $max-lh;
      }
    }
  }
}