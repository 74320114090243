@import "./variables.scss";
// $primTextCol: #3B3B45;
$primTextCol: #041E42;
// $primCol: #CA9F2C;
$primCol: #003DA5;
$primColFiler: brightness(0) saturate(100%) invert(7%) sepia(29%) saturate(7496%) hue-rotate(208deg) brightness(93%) contrast(97%);
$secCol: #005EB8;
$secColFiler: brightness(0) saturate(100%) invert(18%) sepia(97%) saturate(2905%) hue-rotate(189deg) brightness(95%) contrast(92%);

$primBlue: #041E42;

$menuWidth: 384px;
$menuHeight: 95vh;

$cartWidth: 492px;
$cartHeight: 600px;

$searchWidth: 100vw;
$searchHeight: 320px;
$searchHeightExpanded: 635px;

$left: left;
$right: right;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  // overflow-x: hidden;
}

.seoHidden {
  display: none;
}

body {
  color: $primTextCol;
  width: 100vw;
  height: 100vh;
  position: relative;
  // background-color: $secCol;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;

  // background-image: url(../public/citymap.jpg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 126px;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
    background: linear-gradient(0, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    // backdrop-filter: blur(1px);
    // filter: blur(1px);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    pointer-events: none;
    z-index: 5;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    #{$left}: 0;
    width: 100%;
    height: 126px;
    background: linear-gradient(0, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    pointer-events: none;
    z-index: 5;
  }

  &.helpbody {
    width: auto;
    height: auto;

    &::before,
    &::after {
      display: none;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &.primButton {
    display: block;
    padding: 18px 25px;
    background-color: $primCol;
    border-radius: 30px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 115%;
    letter-spacing: 1.12px;
    border: 1px solid $primCol;
    box-shadow: 0px 4px 14px 0px rgba(202, 159, 44, 0.24);

    &.inverse {
      // color: #3B3B45;
      color: $primTextCol;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        background-color: $primCol;
        color: white;
        box-shadow: 0px 4px 14px 0px rgba(202, 159, 44, 0.24);
      }
    }

    &:hover {
      // color: #3B3B45;
      color: $primTextCol;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  // z-index: 6;
  z-index: 8;
  position: absolute;
  width: 100%;
  pointer-events: none;

  .menutoggle,
  .search {
    position: relative;

    a {
      display: block;
      height: 70px;
      width: 70px;
      position: relative;

      img,
      svg,
      canvas {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        #{$left}: 50%;
        transform: translate(-50%, -50%);

        &#searchSVG {
          .st0 {
            fill: none;
            stroke: $primBlue;
            stroke-width: 240;
            stroke-miterlimit: 10;
          }

          .st1 {
            fill: $primBlue;
          }

          .st2 {
            fill: $primBlue;
          }

          #handle {
            transition: all 0.3s ease-in-out;
          }
        }

        &#menuToggle {
          .st0 {
            fill: #FFFFFF;
          }

          .st1 {
            fill: none;
            stroke: $primBlue;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
          }

          #hovered {
            transition: all 0.3s ease-in-out;
          }
        }
      }

      canvas {
        width: 24px !important;
        height: 24px !important;
      }

      .closeButtonCanvas {
        height: 100%;
        width: 100%;

        canvas {
          opacity: 0;
        }
      }

      &:hover {
        #handle {
          opacity: 0;
        }

        #hovered {
          opacity: 0;
        }
      }
    }

    .dynamicBackground {
      position: absolute;
      top: 0;
      #{$left}: 0;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      transition: height 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.28s cubic-bezier(1, 0.01, 0.89, 0.33);
    }

    .menu {
      width: $menuWidth;
      height: calc($menuHeight - 70px);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      padding: 40px 0;
      overflow: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ul {
        list-style-type: none;
        padding: 0 26px;

        li {
          position: relative;
          border-bottom: 1px solid #DDDDDD;

          &:first-child {
            border-top: 1px solid #DDDDDD;
          }

          // &:last-child {
          //   border-bottom: none;
          // }

          a {
            display: block;
            height: auto;
            width: auto;
            padding: 20px 54px;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $primTextCol;
            position: relative;
            transform: translateX(-100%);

            &:hover {
              color: $primCol;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              #{$left}: 10px;
              transform: translateY(-50%);
              width: 26px;
              height: 26px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }

          &.city {
            a {
              &::before {
                background-image: url(../public/home.svg);
              }
            }
          }

          &.help {
            a {
              &::before {
                background-image: url(../public/help.svg);
              }
            }
          }

          &.language,
          &.country {
            border-top: none;
            border-bottom: none;
            position: relative;
            padding-bottom: 85px;

            span {
              color: $primTextCol;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 10px;
              display: block;
            }

            .dropdown {
              border-radius: 30px;
              border: 1px solid $primCol;
              padding: 0;
              position: relative;
              transition: all 0.3s ease-in-out;
              margin-bottom: 20px;
              position: absolute;
              bottom: 0;
              width: 100%;
              background-color: white;
              max-height: 250px;
              overflow: auto;

              &::-webkit-scrollbar {
                display: none;
              }

              &:hover {
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

              }

              &::after {
                content: '';
                display: block;
                position: absolute;
                width: 16px;
                height: 16px;
                top: 25px;
                #{$right}: 20px;
                background-image: url(../public/dropdownArrow.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: all 0.3s ease-in-out;
              }

              &.open {
                &::after {
                  transform: rotateZ(180deg)
                }
              }

              li {
                padding: 0;
                border: none;
              }

              a {
                color: $primTextCol;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 22px 26px;

                &:hover {
                  color: $primCol;
                }
              }
            }
          }
        }
      }

    }
  }

  .logo {
    width: auto;
    height: 89px;
    border-radius: 0px 0px 10px 10px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;

    img,
    svg {
      display: block;
      width: auto;
      max-width: 150px;
      max-height: 100%;

      &.ecommerce-logo {
        max-width: 100%;
      }
    }
  }

  .left,
  .right {
    position: relative;
    overflow: visible;
    padding-top: 20px;
    pointer-events: all;
  }

  .menutoggle {
    min-width: 70px;
  }

  .menutoggle.open {
    .dynamicBackground {
      width: $menuWidth;
      height: $menuHeight;
      border-radius: 20px;
      transition: height 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.1s ease-in-out;
    }

    .trigger {
      .closepath {
        fill: none;
        stroke: $primBlue;
        stroke-width: 3;
        transition: all 0.3s ease-in-out;
        opacity: 1;
      }

      #menuToggle {
        opacity: 0;
      }

      #closeButton {
        opacity: 1;
        width: 20px;
        height: 20px;

        &:hover {
          #hideonHover {
            opacity: 0;
          }
        }
      }

      canvas {
        opacity: 0;
      }

      .closeButtonCanvas {
        canvas {
          opacity: 1;
        }
      }
    }

    .menu {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.2s;

      ul {
        li {
          a {
            transition-delay: 0.05s;
            transform: none;
          }

          &:nth-child(2) {
            a {
              transition-delay: 0.1s;
            }
          }
        }
      }
    }

  }

  .search {
    .dynamicBackground {
      #{$left}: auto;
      #{$right}: 0px;
      min-height: 0;
      overflow: hidden;
    }

    .searchField {
      opacity: 0;
      visibility: hidden;
      position: relative;
      width: 0;
      margin: 0 auto;
      margin-bottom: 50px;

      .field {
        width: 100%;
        border-radius: 40px;
        border: 1px solid $primCol;
        outline: none;
        padding: 15px 30px;
        color: $primTextCol;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .btn {
        position: absolute;
        top: 50%;
        #{$right}: 30px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background-image: url(../public/search-blue.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        outline: none;
        bordeR: none;
        background-color: transparent;
      }
    }

    .suggestions {
      width: calc($searchWidth / 2);
      margin: 0 auto;
      padding: 0 0 0 30px;

      .results {
        color: $primTextCol;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 28px;
      }

      ul {
        list-style-type: none;
        max-height: 280px;
        overflow: auto;
        padding-#{$right}: 30px;

        &::-webkit-scrollbar-track {
          border-radius: 2px;
          background-color: #D8D7D4;
        }

        &::-webkit-scrollbar {
          width: 2px;
          background-color: #D8D7D4;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: $primCol;
        }

        li {
          padding: 9px 0;
          border-bottom: 1px solid #DDDDDD;
          padding-#{$left}: 40px;
          color: $primTextCol;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          position: relative;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $primCol;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            #{$left}: 0;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-size: contain;
            background-repeat: no-repeat;
          }

          &.location {
            &::before {
              background-image: url(../public/locationIcon.svg);

            }
          }

          &.product {
            &::before {
              background-image: url(../public/productIcon.svg);

            }
          }

          .path {
            color: $primTextCol;
            font-size: 14px;
            opacity: 0.5;
          }
        }
      }
    }

    &.open {
      .dynamicBackground {
        width: calc($searchWidth - 40px);
        height: $searchHeight;
        border-radius: 20px;
        transition: padding-top 0.3s ease-in-out, height 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.1s ease-in-out;
        // top: -20px;
        // #{$right}: -20px;
        // border-radius: 0;
        padding-top: 145px;
      }

      .searchField {
        opacitY: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out;
        transition-delay: 0.5s;
        width: calc($searchWidth / 2);
      }

      canvas {
        opacity: 0;
      }

      .closeButtonCanvas {
        canvas {
          opacity: 1;
        }
      }

      &.expanded {
        .dynamicBackground {
          height: $searchHeightExpanded;
        }
      }

      .trigger {
        .closepath {
          fill: none;
          stroke: $primBlue;
          stroke-width: 3;
          transition: all 0.3s ease-in-out;
          opacity: 1;
        }

        #searchSVG {
          opacity: 0;
        }

        #closeButton {
          opacity: 1;
          width: 20px;
          height: 20px;

          &:hover {
            #hideonHover {
              opacity: 0;
            }
          }
        }
      }
    }
  }

}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px;
  z-index: 6;

  .footerPlaceHolder {
    width: 70px;
    height: 70px;

  }

  .swipeHint {
    padding: 10px;
    border-radius: 4px;
    background: rgba(152, 152, 152, 0.50);

    span {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      position: relative;
      margin-bottom: 10px;
      display: block;
      padding-#{$left}: 44px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        #{$left}: 10px;
        transform: translateY(-50%);
        width: 23px;
        height: 23px;
        background-image: url(../public/drag.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.click {
        &::before {
          background-image: url(../public/click.svg);
        }
      }
    }


  }

  .cartToggle {
    position: relative;
    min-width: 70px;

    .trigger {
      display: block;
      height: 70px;
      width: 70px;
      position: relative;

      img,
      svg,
      canvas {
        display: block;
        width: 24px !important;
        height: 24px !important;
        position: absolute;
        top: 50%;
        #{$left}: 50%;
        transform: translate(-50%, -50%);

        &#cart {
          .st0 {
            fill: none;
            stroke: $primBlue;
            stroke-width: 2.3625;
            stroke-linecap: round;
            stroke-linejoin: round;
          }

          #dashes {
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &:hover {
        #dashes {
          opacity: 0;
        }
      }

      .closeButtonCanvas {
        width: 100%;
        height: 100%;

        canvas {
          opacity: 0;
        }
      }
    }

    .dynamicBackground {
      position: absolute;
      bottom: 0;
      #{$right}: 0;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      transition: height 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.28s cubic-bezier(1, 0.01, 0.89, 0.33);
      overflow: hidden;

      .head {
        color: $primTextCol;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 115%;
        letter-spacing: -0.3px;
        opacity: 0;
        visibility: hidden;
        background: var(--Surface-Background-100, #FAF5EA);
        padding: 34px 28px;
      }

      .cartListing {
        opacity: 0;
        visibility: hidden;

        .empty {
          padding: 50px 0;
          text-align: center;

          img {
            width: 295px;
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;
          }

          p {
            color: $primTextCol;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.18px;
            max-width: 60%;
            margin: 0 auto;
          }
        }

        .items {
          padding: 28px;
          max-height: calc($cartHeight - 200px);
          // border: 1px solid red;
          overflow: auto;

          &::-webkit-scrollbar-track {
            background: #F4F4F4;
            border-radius: 30px;

          }

          &::-webkit-scrollbar-thumb {
            background: #DDDDDD;
            border-radius: 30px;

          }

          &::-webkit-scrollbar {
            width: 6px;
            border-radius: 30px;

          }

          .item {
            border-radius: 10px;
            background: #F5F5F5;
            padding: 10px;
            display: flex;
            margin-bottom: 14px;

            &:last-child {
              margin-bottom: 0;
            }

            .itemImage {
              margin-#{$right}: 30px;
              width: 135px;
              // border-radius: 5px;
              // overflow: hidden;

              img {
                width: 100%;
                display: block;
                border-radius: 5px;
              }
            }

            .itemInfo {
              width: calc(100% - 165px);

              .itemTitle {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
              }

              .itemSKU {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 15px;
              }

              .itemFooter {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .qty {
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .remove {
                  display: block;
                  width: 15px;
                  height: 15px;
                  background-image: url(../public/trash.svg);
                  background-position: center;
                  background-size: contain;
                  background-repeat: no-repeat;

                  &:hover {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }

        .checkoutHolder {
          margin-top: 20px;
          text-align: $right;
          position: absolute;
          bottom: 20px;
          #{$right}: 28px;

          a {
            display: inline-block;
          }
        }
      }
    }

    &.open {
      .dynamicBackground {
        width: $cartWidth;
        height: $cartHeight;
        border-radius: 20px;
        transition: height 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.1s ease-in-out;

        .head,
        .cartListing {
          opacity: 1;
          visibility: visible;
          transition-delay: 0.3s;
        }
      }

      .trigger {
        transform: translateY(calc(-1 * calc($cartHeight - 85px)));
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        margin-#{$right}: 28px;

        #cart {
          opacity: 0;
        }

        #closeButton {
          opacity: 1;
          width: 20px;
          height: 20px;

          .closepath {
            fill: none;
            stroke: $primBlue;
            stroke-width: 2;
            transition: all 0.3s ease-in-out;
            opacity: 1;
          }
        }

        &:hover {
          #closeButton {
            #hideonHover {
              opacity: 0;
            }
          }

        }

        canvas {
          opacity: 0;
        }

        .closeButtonCanvas {
          canvas {
            opacity: 1;
          }
        }

      }

      .head {
        opacity: 1;
      }


    }
  }
}

.indiana-scroll-container {
  // border:1px solid red;
  height: 100%
}

.mapHolder {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  // top:0;
  // #{$left}:50%;
  // transform:translateX(-50%);
  // z-index:-1;
  background-color: $primBlue;

  &::-webkit-scrollbar {
    display: none;
  }

  .mapWrapper {
    position: relative;
    height: 100%;
    min-width: 100%;
    text-align: center;
    // border:1px solid red;
    // width:100%;

    .noPointerEvents {
      position: relative;
      opacity: 0;

      &.active {
        opacity: 1;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .map {
      display: block;
      max-width: none;
      transition: all 0.5s ease-in-out;

      &.loadingimg {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        filter: blur(10px);
      }
    }

    .pin {
      position: absolute;
      background-color: white;
      color: $primBlue;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 7px 10px;
      border-radius: 30px;
      z-index: 5;
      transform: translate(-50%, calc(-100% - 15px));
      opacity: 0;
      transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

      &.showpin {
        opacity: 1;
      }

      &.loading {
        padding-#{$right}: 35px;
        transition-delay: 0ms !important;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          #{$right}: 10px;
          width: 20px;
          height: 20px;
          transform: translateY(-50%);
          background-image: url(../public/loading.gif);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;

        }
      }

      input[type="text"] {
        border: none;
        text-align: center;
        display: inline-block;
        width: 100px;

        &:focus {
          outline: none;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        #{$left}: 50%;
        transform: translateX(-50%);
        width: 15px;
        height: 15px;
        background-image: url(../public/locationPin.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: brightness(0) invert(1);
      }

      &:not(.nohover):hover {
        background-color: $secCol;
        color: white;

        &::after {
          filter: none;
        }
      }

      &.hidden {
        display: none !important;
      }
    }

    .noPointerEvents {
      // height: 100vh;
      // width: 100vw;
      // border:1px solid yellow;
      // overflow-y: hidden;
      // background-size: cover;
      // background-position: center;
      // background-repeat: no-repeat;
      // position: relative;

      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        opacity: 0;
        height: 100%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // height: auto;
        // width: auto;
        // max-width: 100%;
        // min-width: 100%;
        // min-height:100%;
        // max-height:100%;
      }
    }
  }

  .pageTitle {
    position: absolute;
    top: 80px;
    #{$left}: 120px;
    color: #ffffff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 5;
    pointer-events: none;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  }

  .backbtn {
    color: #ffffff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    // top: calc(50% + 10px);
    // transform: translateY(-50%);
    // #{$left}: calc(100% + 30px);
    // width: 200px;
    padding-#{$left}: 30px;
    text-shadow: 0px 0px 10px #595858;
    position: absolute;
    top: 44px;
    #{$left}: 120px;
    z-index: 5;

    &:hover {
      &::before {
        border: 1px solid white;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      #{$left}: 0;
      transform: translateY(-50%);
      background-image: url(../public/backarrow.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: center;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      border-radius: 50%;

    }
  }
}

.productsPopup {
  position: fixed;
  top: 0;
  #{$left}: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;

  &.fadein {
    opacity: 1;
    visibility: visible;
  }

  .content {
    position: absolute;
    top: 50%;
    #{$left}: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 1400px;
    max-height: 90vh;
    max-width: 90vw;
    min-width: 1100px;
    display: flex;
    flex-direction: column;

    .pophead {
      background-color: #FAF5EA;
      padding: 25px 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.align-flex-start {
        align-items: flex-start;
      }

      .title {
        color: $primTextCol;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .close {
        display: block;
        width: 70px;
        height: 70px;
        // background-image: url(../public/close.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        position: relative;
        flex-shrink: 0;

        svg {
          position: absolute;
          top: 50%;
          #{$left}: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          transition: all 0.3s ease-in-out;

          .closepath {
            fill: none;
            stroke: $primBlue;
            stroke-width: 2;
            transition: all 0.3s ease-in-out;
            opacity: 1;
          }
        }

        &:hover {
          background-size: contain;

          svg {
            #hideonHover {
              opacity: 0;
            }
          }

        }
      }

      .detailsHead {
        .backtoProducts {
          display: block;
          color: $secCol;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-#{$left}: 25px;
          position: relative;
          transition: all 0.3s ease-in-out;
          margin-bottom: 22px;

          &:hover {
            &::before {
              border: 1px solid $secCol;
            }
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            #{$left}: 0;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-image: url(../public/backtoprods.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 0.3s ease-in-out;
            border-radius: 50%;
            border: 1px solid transparent;
          }
        }

        .title {
          color: $primTextCol;
          font-size: 35px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 10px;
        }

        .sku {
          color: #8F8F8F;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .popbody {
      background-color: #ffffff;
      padding: 34px;
      max-height: calc(90vh - 127px);

      &.smaller {
        height: calc(90vh - 175px);
        overflow: auto;

        &::-webkit-scrollbar-track {
          background: #F4F4F4;
          border-radius: 30px;

        }

        &::-webkit-scrollbar-thumb {
          background: #DDDDDD;
          border-radius: 30px;

        }

        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 30px;

        }
      }

      .productsList {
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        max-height: calc(90vh - 195px);

        &::-webkit-scrollbar-track {
          background: #F4F4F4;
          border-radius: 30px;

        }

        &::-webkit-scrollbar-thumb {
          background: #DDDDDD;
          border-radius: 30px;

        }

        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 30px;

        }

        .product {
          flex: 0 0 25%;
          max-width: 25%;
          padding: 17px 17px 26px 17px;
          border-#{$right}: 1px solid #EBEBEC;
          border-bottom: 1px solid #EBEBEC;
          transition: all 0.3s ease-in-out;

          .productImage {
            margin-bottom: 20px;
            overflow: hidden;
            height: 300px;

            img {
              display: block;
              width: auto;
              margin: 0 auto;
              max-width: none;
              max-height: 300px;
              max-width: 100%;
              object-fit: contain;
            }
          }

          .productInfo {
            .productTitle {
              color: $primTextCol;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 10px;
            }

            .productDescription {
              color: #8F8F8F;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          &:hover {
            background: rgba(235, 235, 236, 0.30);
            cursor: pointer;
          }

          &:nth-child(4n) {
            border-#{$right}: none;
          }
        }
      }

      .detailsBody {
        height: 100%;

        .d-flex {
          display: flex;
          margin: 0 -30px;
          height: 100%;

          .col-md-6 {
            flex: 0 0 50%;
            padding: 0 30px;
            width: 100%;
            max-width: 50%;

            img {
              width: auto;
              display: block;
              transition: scale 0.3s ease-in-out;
              cursor: zoom-in;
              max-width: 400px;
              max-height: 400px;
              margin: 0 auto;
            }

            .swiperSlider {
              padding: 0 50px;
            }

            .swiper-pagination {
              .swiper-pagination-bullet {
                margin: 0;
                margin-#{$right}: 4px;
                width: 6px;
                height: 6px;
                opacity: 0.5;
                transition: all 0.3s ease-in-out;
                background-color: #DDDDDD;

                &.swiper-pagination-bullet-active {
                  opacity: 1;
                }
              }
            }

            .mySwiper {
              margin-bottom: 40px;
            }

            .thumbsSwiper {
              .swiper-slide {
                opacity: 0.2;
                transition: opacity 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                  opacity: 0.5;
                }

                img {
                  transform: scale(0.8);
                  transition: transform 0.3s ease-in-out;
                }

                &.swiper-slide-thumb-active {
                  opacity: 1;

                  img {
                    transform: scale(1);
                  }
                }
              }
            }

            .swiper-thumbs {
              img {
                max-width: 100%;
                width: 100%;
              }
            }

            .infoContainer {
              height: calc(100% - 155px);
              overflow-x: hidden;
              overflow-y: auto;
              padding-#{$right}: 50px;
              margin-bottom: 100px;

              &.stretched {
                height: 505px;
                margin-bottom: 0;
              }

              &::-webkit-scrollbar-track {
                background: #F4F4F4;
                border-radius: 30px;

              }

              &::-webkit-scrollbar-thumb {
                background: #DDDDDD;
                border-radius: 30px;

              }

              &::-webkit-scrollbar {
                width: 6px;
                border-radius: 30px;

              }

              p {
                color: $primTextCol;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;
              }

              .documents {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
                margin: 0 -10px;

                .documentWrapper {
                  flex: 0 0 50%;
                  max-width: 50%;
                  padding: 0 10px;
                  margin-bottom: 20px;
                }

                .document {
                  display: block;
                  border-radius: 10px;
                  border: 1px solid $primCol;
                  padding: 15px 26px 17px;
                  color: $primTextCol;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  position: relative;

                  &:hover {
                    background-color: $primCol;
                    color: white;

                    &::after {
                      filter: brightness(0) invert(1);
                    }
                  }

                  &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    #{$right}: 17px;
                    width: 36px;
                    height: 36px;
                    background-image: url(../public/download.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transform: translateY(-50%);
                    transition: all 0.3s ease-in-out;
                    filter: brightness(0) saturate(100%) invert(12%) sepia(33%) saturate(1920%) hue-rotate(185deg) brightness(100%) contrast(108%);
                  }
                }
              }

              .specs {
                ul {
                  list-style-type: none;

                  li {
                    color: #8F8F8F;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    position: relative;
                    padding-#{$left}: 20px;
                    margin-bottom: 5px;

                    span {
                      margin-#{$right}: 10px;
                    }

                    &::before {
                      content: '';
                      display: block;
                      position: absolute;
                      top: 8px;
                      // transform: translateY(-50%);
                      width: 6px;
                      #{$left}: 5px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: #8F8F8F;
                    }

                  }
                }
              }

              .rawHTML {
                ul {
                  list-style-type: none;

                  li {
                    color: #8F8F8F;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    position: relative;
                    padding-#{$left}: 20px;
                    margin-bottom: 5px;

                    &::before {
                      content: '';
                      display: block;
                      position: absolute;
                      top: 8px;
                      // transform: translateY(-50%);
                      width: 6px;
                      #{$left}: 5px;
                      height: 6px;
                      border-radius: 50%;
                      background-color: #8F8F8F;
                    }

                  }
                }

                b {
                  color: $primTextCol;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  display: inline-block;
                  margin-#{$right}: 5px;
                }

                &.moredetails {
                  padding-top: 20px;
                }
              }

            }

            .actions {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .cartAction {
                position: relative;

                .addtocartbtn {
                  padding-#{$left}: 50px;
                  position: relative;

                  &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    #{$left}: 25px;
                    transform: translateY(-50%);
                    width: 15px;
                    height: 15px;
                    background-image: url(~/public/cart.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    filter: brightness(0) invert(1);
                    transition: all 0.3s ease-in-out;
                  }

                  &:hover {
                    &::before {
                      filter: none;
                    }
                  }

                }

                .updatedCartMessage {
                  position: absolute;
                  bottom: calc(100% + 20px);
                  #{$right}: 0%;
                  // transform: translateX(-50%);
                  width: 415px;
                  padding: 40px 33px;
                  border-radius: 20px;
                  background: #FFF;
                  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.18);
                  opacity: 0;
                  visibility: hidden;
                  transition: all 0.3s ease-in-out;

                  &.show {
                    opacity: 1;
                    visibility: visible;
                  }

                  span {
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 16px;
                    display: block;
                  }

                  .btns {
                    display: flex;
                    justify-content: space-between;

                    .primButton {
                      // flex: 0 0 50%;
                      min-width: 164px;
                      text-align: center;
                      padding: 18px 12px;

                      &:last-child {
                        margin: 0;
                      }
                    }
                  }

                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 100%;
                    transform: translateY(-50%) rotateZ(45deg);
                    width: 15px;
                    #{$right}: 75px;
                    height: 15px;
                    background-color: white;
                    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.18);
                  }
                }
              }

              .sentence {
                color: #000;
                font-size: 16px;
                font-style: normal;
                // font-weight: 700;
                line-height: normal;
              }
            }

          }
        }
      }
    }
  }
}

.quantity {
  display: flex;
  align-items: center;

  label {
    margin-#{$right}: 20px;
  }

  .btn {
    width: 35px;
    height: 35px;
    display: block;
    margin-#{$right}: 14px;
    border: 1px solid $secCol;
    border-radius: 50%;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      #{$left}: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover {
      background-color: $secCol;

      &::after {
        filter: brightness(0) invert(1);
      }
    }

    &.more {
      margin-#{$right}: 0;

      &::after {
        background-image: url(../public/plus.svg);
      }
    }

    &.less {
      &::after {
        background-image: url(../public/minus.svg);
      }
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .number {
    color: $primTextCol;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-#{$right}: 14px;
  }
}

.fullscreenImgContainer {
  position: fixed;
  z-index: 11111111111111111;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  #{$left}: 0;
  width: 100vw;
  height: 100vh;
  cursor: zoom-out;

  img {
    display: block;
    position: absolute;
    top: 50%;
    #{$left}: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 70vw;
    max-height: 70vh;
    min-width: 20vw;
    min-height: 20vh;
  }
}

.helpPage {
  background-color: #FAF5EA;
  width: 100%;
  padding-top: 200px;

  .title {
    color: $primTextCol;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 50px;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
  }

  .navTabs {
    border-top: 1px solid #d4dfdf;
    max-width: none;

    .nav {
      display: flex;
      padding: 0;

      .navItem {
        color: $primTextCol;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: all 0.3s ease-in-out;
        list-style-type: none;
        padding: 10px 10px 20px;
        border-top: 2px solid transparent;
        margin-#{$right}: 30px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $secCol;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -3px;
          #{$left}: 0;
          width: 100%;
          height: 4px;
          background-color: transparent;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
        }

        &.active {
          color: $secCol;

          &::before {
            background-color: $secCol;
          }

        }
      }
    }
  }

  .tabsContent {
    background-color: white;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .itemsListing {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    .listingItem {
      flex: 0 0 50%;
      padding: 0 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 77px;

      &:last-child {
        margin-bottom: 85px;
      }

      .listingImage {
        flex: 0 0 40%;
        margin-#{$right}: 20px;
      }

      .listingInfo {
        flex: 0 0 calc(100% - 40%);

        .listingTitle {
          color: $primTextCol;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 16px;
        }

        .listingDescription {
          color: $primTextCol;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .smallFooter {
    border-top: 1px solid #DDDDDD;
    color: $primTextCol;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-top: 40px;
  }
}

.notfound {
  background-image: url(../public/notfound.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: block;
}

.loader {
  position: fixed;
  top: 0;
  #{$left}: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6);
  // pointer-events: none;
  background-image: url(../public/logoNapco-3-bilingual.png);
  background-position: center calc(50% - 20px);
  background-size: 127px;
  background-repeat: no-repeat;

  &.ecommerce {
    background-image: url(../public/logoWoosooq-2.png);
  }

  &.transparentBg {
    background-color: white;

    &::after {
      // animation: spinload infinite forwards 0.7s linear;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% + 30px);
    #{$left}: calc(50% - 65px);
    transform: translate(0, -50%);
    // width: 50px;
    // height: 50px;
    width: 130px;
    height: 4px;
    // border-radius: 50%;
    border-radius: 5px;
    // border: 2px solid transparent;
    // border-top: 2px solid $primBlue;
    // animation: spinload infinite forwards 1s linear;
    border: none;
    // border-radius: 50%;
    // background-color: $primBlue;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #ffffff 20%, $primBlue 60%, #ffffff 60%);
    background-size: 800px 104px;
    // filter: brightness(0) saturate(100%) invert(13%) sepia(33%) saturate(4693%) hue-rotate(200deg) brightness(96%) contrast(103%);
    // outline: 2px solid rgba(255, 255, 255, 0.7);
    // background-image: url('https://cdn.dribbble.com/users/1053052/screenshots/3600670/media/049491d00605f54d441aa47b9b419910.gif');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
  }

  span {
    position: absolute;
    top: calc(50% + 43px);
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 16px;
    color: rgba(0, 43, 98, 1);
    font-weight: bold;
    z-index: 1;
  }
}

.noPointerEvents {
  pointer-events: none;
  overflow: hidden;
}

@keyframes spinload {
  0% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }

  100% {
    background-position: 800px 0
  }
}

.preloader {
  // opacity: 0;

  &.loaded {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
}

.foreground {
  transition: opacity 0.3s ease;
}


.password-container {
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    position: relative;
  }

  img {
    width: 200px;
    margin-bottom: 30px;
  }

  input {
    border: none;
    text-align: center;
    display: inline-block;
    width: 100px;
    border: 2px solid $primBlue;
    padding: 10px;
    font-size: 14px;
    border-radius: 20px;
    width: 200px;
    display: block;
    text-align: $left;

    &.error {
      border-color: red;
    }


    &:focus {
      outline: none;
    }
  }

  button {
    border: 2px solid $primBlue;
    padding: 0px;
    font-size: 0;
    border-radius: 20px;
    display: block;
    width: auto;
    background-color: transparent;
    margin: 0 auto;
    background-color: $primBlue;
    color: white;


    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateZ(180deg);
    right: 4px;
    border: 50%;
    width: 30px;
    height: 30px;
    background-image: url(../public/backarrow.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;

  }
}

.categoriesMenuWrapper {
  position: fixed;
  bottom: 30px;
  inset-inline-start: 25px;
  z-index: 7;
  transition: all 0.3s ease-in-out;
  width: 225px;
  height: 50px;
  overflow: hidden;
  background-color: white;
  border-radius: 70px;

  &.open {
    height: calc(100vh - 140px);
    width: 33%;
    border-radius: 20px;

    .categoryButton {
      opacity: 0;
      pointer-events: none;
      margin-top: -50px;
    }

    .categoriesMenu {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.selectedCategory {
    width: 66%;
  }

  .backbtn {
    display: none;
  }

  .categoryButton {
    width: 225px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid transparent;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    color: $primTextCol;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: "Lato", sans-serif;
    display: flex;
    align-items: center;

    .categImg {
      display: flex;
      align-items: center;

      svg {
        overflow: visible; // Allow content to overflow

        clipPath {
          overflow: visible;
        }
      }

      path {
        transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      .categImg {

        path {

          &:first-child {
            transform: translateY(-3px);
          }

          &:last-child {
            transform: translateY(3px);
          }
        }
      }
    }
  }

  .categoriesMenu {
    opacity: 0;
    pointer-events: none;
    border-radius: 70px;
    height: 100%;

    .menuHeader {
      padding: 25px 32px;
      border-radius: 20px 20px 10px 10px;
      background-color: #FAF5EA;
      color: $primTextCol;
      display: flex;
      gap: 20px;
      align-items: center;

      h2 {
        font-size: 30px;
        @include font(20px, 30px);
        font-weight: 700;

        span {
          display: block;
          @include font(16px, 18px);
          font-weight: 400;
          margin-top: 5px;
          color: $secCol;
        }
      }

      .closeMenu {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: none;
        background-color: white;
        background-image: url("../public/close.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        flex-shrink: 0;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }

    .menuBody {
      display: flex;
      height: calc(100% - 120px);

      &.selectedCategory {
        .firstLevelItems {
          flex: 0 0 50%;
          max-width: 50%;
        }

        .secondLevelItems {
          flex: 0 0 50%;
          max-width: 50%;
          opacity: 1;
        }
      }

      .firstLevelItems,
      .secondLevelItems {
        max-height: 100%;
        overflow: auto;
        min-width: 350px;

        &::-webkit-scrollbar-track {
          background: #F4F4F4;
          border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb {
          background: #DDDDDD;
          border-radius: 30px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 30px;
        }
      }

      .firstLevelItems {
        flex: 0 0 100%;
        max-width: 100%;
        list-style: none;
        border-inline-end: 1px solid rgba(221, 221, 221, 0.60);
        height: 100%;
        padding: 23px 32px;

      }

      li {
        padding: 20px 0;
        display: flex;
        align-items: center;
        gap: 15px;
        border-bottom: 1px solid #DDD;
        cursor: pointer;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 9px;
          height: 18px;
          background-image: url("../public/button-arrow.svg");
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
          // transition: all 0.3s ease-in-out;
          position: absolute;
          inset-inline-end: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover,
        &.selected {
          p {
            color: $secCol;
          }

          &::after {
            filter: $secColFiler;
          }

          // img {
          //   filter: $secColFiler;
          // }
        }

        p {
          @include font(14px, 17px);
          font-weight: 400;
          color: $primTextCol;
          transition: all 0.3s ease-in-out;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          // filter: $primColFiler;
          transition: all 0.3s ease-in-out;
        }
      }

      .secondLevelItems {
        flex: 0 0 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        padding: 23px 32px;

      }
    }
  }
}


// @media all and (max-width:1500px)  {
//   .mapHolder .mapWrapper .noPointerEvents {
//     height: 100%;
//     width: 100%;
//     background-size: contain;
//     background-position: left top;
//   }
// }

@media all and (max-width:1200px) {

  footer .cartToggle.open .trigger,
  .productsPopup .content .pophead .close,
  header .menutoggle .dynamicBackground,
  header .search .dynamicBackground,
  header .menutoggle a,
  header .search a,
  footer .cartToggle .dynamicBackground {
    width: 40px;
    height: 40px;

    canvas {
      width: 18px !important;
      height: 18px !important;
    }
  }

  footer {
    .cartToggle {
      min-width: 42px;

      .trigger {
        width: 40px;
        height: 40px;

        canvas {
          width: 20px !important;
          height: 20px !important;
        }
      }

      &.open .trigger {
        // transform: translateY(calc(-80vh + 86px));
        margin-right: 20px;
        transform: translateY(-554px);
      }
    }
  }

  .productsPopup .content .pophead .close img,
  .productsPopup .content .pophead .close svg,
  header .menutoggle a img,
  header .menutoggle a svg,
  header .search a img,
  header .search a svg,
  footer .cartToggle .trigger img,
  footer .cartToggle .trigger svg {
    height: 16px;
    width: 16px;
  }

  footer .cartToggle:not(.open) .trigger img,
  footer .cartToggle:not(.open) .trigger svg {
    // transform: translate(20%, 20%);
  }

  .mapHolder {
    .backbtn {
      top: 30px;
      left: 80px;
      font-size: 16px;
    }

    .pageTitle {
      top: 65px;
      left: 80px;
      font-size: 30px;
    }
  }

  .productsPopup .content .pophead {
    padding: 14px 20px;

    .detailsHead {
      .title {
        font-size: 26px;
      }

      .backtoProducts {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
  }

  footer .cartToggle.open .dynamicBackground .head,
  footer .cartToggle.open .dynamicBackground .cartListing {
    padding: 14px 20px;
    font-size: 22px;
  }

  footer .cartToggle .dynamicBackground .cartListing .items {
    padding: 10px;
    max-height: 440px;
  }


  header .search.open .dynamicBackground {
    padding-top: 80px;
  }

  header .search.open.expanded .dynamicBackground {
    height: 450px;
  }

  header .search .suggestions ul {
    max-height: 200px;
  }

  header {
    .logo {
      width: 135px;
      height: 60px;
      // padding: 6px 12px;

      img,
      svg {
        max-width: 100px;
      }
    }

    .search .searchField .btn {
      width: 20px;
      height: 20px;
    }
  }

  .productsPopup .content {
    min-width: 992px;
  }

  .productsPopup .content .popbody .detailsBody .d-flex .col-md-6 img {
    max-width: 100%;
  }

  .categoriesMenuWrapper {
    width: 225px;
    height: 50px;

    &.open {
      height: calc(100vh - 140px);
      width: 50%;
      border-radius: 20px;

      .categoryButton {
        opacity: 0;
        pointer-events: none;
        margin-top: -50px;
      }

      .categoriesMenu {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.selectedCategory {
      width: calc(100% - 50px);
    }

    .categoryButton {
      width: 225px;
      height: 50px;
      gap: 10px;
      padding: 10px 20px;
      font-size: 14px;

      &::before {
        width: 14px;
        height: 14px;
      }
    }

    .categoriesMenu {
      .menuHeader {
        padding: 15px;
        border-radius: 20px 20px 10px 10px;
        gap: 20px;

        .closeMenu {
          width: 50px;
          height: 50px;
          background-size: 15px;
        }
      }

      .menuBody {
        height: calc(100% - 80px);

        &.selectedCategory {
          .firstLevelItems {
            flex: 0 0 50%;
            max-width: 50%;
          }

          .secondLevelItems {
            flex: 0 0 50%;
            max-width: 50%;
            opacity: 1;
          }
        }

        .firstLevelItems {
          flex: 0 0 100%;
          max-width: 100%;
          border-inline-end: 1px solid rgba(221, 221, 221, 0.60);
          height: 100%;
          padding: 23px 32px;

        }

        li {
          padding: 20px 0;
          gap: 15px;
          border-bottom: 1px solid #DDD;

          &::after {
            width: 9px;
            height: 18px;
            background-size: 10px;
            inset-inline-end: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          img {
            width: 30px;
            height: 30px;
          }
        }

        .secondLevelItems {
          padding: 23px 32px;

        }
      }
    }
  }

  header {
    .menutoggle {
      min-width: 40px;
    }
  }
}

@media all and (max-width:1024px) {
  .indiana-scroll-container {
    height: 100%;
  }

  .productsPopup .content {
    max-width: calc(100vw - 40px);
    width: 100%;
    min-height: 0;
    min-width: 0;

    .popbody {
      .detailsBody .d-flex {
        .col-md-6 {
          &:first-child {
            flex: 0 0 30%;
            width: 30%;
            max-width: 30%;

            .swiperSlider {
              padding: 0;

            }
          }

          &:last-child {
            flex: 0 0 70%;
            max-width: 70%;

            .actions .cartAction .updatedCartMessage {
              width: 400px;
              z-index: 1;

              .btns {
                flex-direction: column;

                .primButton {
                  flex: 0 0 100%;
                  margin-bottom: 15px;

                  &:last-child {
                    margin: 0;
                  }
                }
              }
            }
          }
        }

      }
    }
  }

  header .search.open .searchField {
    width: calc(100vw - 80px);
  }

  footer .cartToggle.open {
    .trigger {
      // transform: translateY(calc(-70vh + 125px));
      transform: translateY(calc(-70vh + 86px));
    }

    .dynamicBackground {
      width: 500px;
      height: calc(70vh - 40px);
    }
  }

  .container {
    width: 100%;
    padding: 0 20px;
  }

  .helpPage {
    padding-top: 100px;

    .title {
      font-size: 30px;
    }

    .navTabs .nav .navItem {
      font-size: 14px;
      padding: 10px;
      margin-#{$right}: 15px
    }

    .tabsContent {
      padding: 30px 0;
    }


  }

  header .search .searchField .field {
    font-size: 14px;
  }

  footer .cartToggle .dynamicBackground .cartListing .items {
    max-height: 360px;
  }
}

@media all and (max-width:768px) {
  .helpPage {
    .itemsListing {
      padding: 0;
      flex-direction: column;

      .listingItem {
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;

        .listingImage {
          margin: 0;
          flex: 0 0 100%;
          margin-bottom: 20px;
          width: 100%;

          img {
            display: block;
            width: 100%;
          }
        }

        .listingInfo {
          .listingTitle {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .listingDescription {
            font-size: 14px;
          }
        }

      }
    }
  }

  footer .cartToggle .trigger {
    height: 42px;
    width: 42px;
  }

  footer .cartToggle .dynamicBackground {
    height: 42px;
    width: 42px;
  }

  footer .footerPlaceHolder {
    width: 42px;
  }

  footer .cartToggle {
    min-width: 42px;
  }

  footer {
    padding: 0 20px 10px;
  }


  footer .swipeHint span {
    font-size: 12px;
    padding-#{$left}: 24px;

    &::before {
      #{$left}: 0px;
      width: 20px;
      height: 20px;
    }
  }

  footer .cartToggle.open .dynamicBackground {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
  }

  footer .cartToggle.open .trigger {
    transform: translateY(calc(-100vh + 125px));
  }

  footer .cartToggle .dynamicBackground .head {
    font-size: 20px;
    line-height: 1;
    padding: 20px 15px;
  }

  // footer .cartToggle .dynamicBackground .cartListing .items {
  //   padding: 15px;
  // }

  footer .cartToggle .dynamicBackground .cartListing .items .item .itemImage {
    margin-#{$right}: 10px;
    width: 65px;
  }

  footer .cartToggle .dynamicBackground .cartListing .items .item .itemInfo {
    width: calc(100% - 65px);
  }

  footer .cartToggle .dynamicBackground .cartListing .items .item .itemInfo .itemTitle {
    font-size: 13px;
  }

  footer .cartToggle .dynamicBackground .cartListing .items .item .itemInfo .itemSKU {
    font-size: 11px;
  }


  header {

    .menutoggle a,
    .search a,
    .menutoggle .dynamicBackground,
    .search .dynamicBackground {
      height: 42px;
      width: 42px;
    }

    .menutoggle a img,
    .menutoggle a svg,
    .search a img,
    .search a svg {
      width: 16px;
      height: 16px;
    }

    .menutoggle a canvas,
    .search a canvas {
      width: 16px !important;
      height: 16px !important;
    }

    .logo {
      width: 120px;
      height: 52px;
    }

    .left,
    .right {
      padding-top: 10px;
    }

    .search.open .dynamicBackground {
      height: 140px;
      padding-top: 65px;
    }

    .search {
      &.open .searchField {
        width: calc(100vw - 60px);
      }

      .searchField {
        margin-bottom: 40px;

        .field {
          padding: 12px;
        }

        .btn {
          #{$right}: 15px;
          width: 16px;
          height: 16px;
        }
      }
    }

    .menutoggle {
      min-width: 42px;
    }
  }

  header .menutoggle.open .menu {
    height: 79vh;
  }

  header .menutoggle .menu,
  header .menutoggle.open .dynamicBackground {
    width: calc(80vw - 40px);
    height: 83vh;
  }

  footer .cartToggle.open .trigger #closeButton,
  header .search.open .trigger #closeButton,
  header .menutoggle.open .trigger #closeButton {
    width: 14px;
    height: 14px;
  }

  header .menutoggle .menu ul {
    padding: 0 15px;

    li a {
      padding: 10px 30px;
      font-size: 14px;

      &::before {
        #{$left}: 0px;
        width: 16px;
        height: 16px;
      }
    }

    li.language,
    li.country {
      label {
        font-size: 14px;
      }

      .dropdown {
        border-radius: 10px;

        a {
          padding: 12px;
          font-size: 12px;
        }

        &::after {
          top: 12px;
          #{$right}: 10px;
        }
      }
    }

    li.country {
      .dropdown {
        margin: 0;
      }
    }
  }

  .quantity {
    .btn {
      width: 25px;
      height: 25px;
    }

    .number {
      font-size: 14px;
    }
  }

  footer .cartToggle.open .trigger {
    transform: translateY(calc(-100vh + 95px));
    margin-#{$right}: 15px;
  }

  a.primButton {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 1;
  }

  footer .cartToggle .dynamicBackground .cartListing .checkoutHolder {
    text-align: center;
    bottom: 10px;
    #{$right}: 10px;
    width: calc(100% - 20px);

    a.primButton {
      width: 100%;
      padding: 15px;
    }
  }

  .mapHolder .backbtn {
    font-size: 14px;
    padding-#{$left}: 30px;
    top: 110px;
    #{$left}: 15px;
  }

  .mapHolder .pageTitle {
    top: 140px;
    #{$left}: 15px;
    font-size: 24px;
  }

  .productsPopup {
    z-index: 8;
  }

  .productsPopup .content {
    max-height: 100vh;
    max-width: 100vw;
    border-radius: 0px;


    .pophead {

      padding: 20px 15px;
      border-radius: 0px 0px 20px 20px;

      .title {
        font-size: 20px;
        line-height: 1;
      }

      .close {
        height: 42px;
        width: 42px;
        flex: 0 0 42px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      .detailsHead {
        padding: 20px 0;

        .backtoProducts,
        .sku {
          font-size: 12px;
        }

        .backtoProducts {
          margin-bottom: 10px;
          padding-#{$left}: 20px;

          &::before {
            width: 12px;
            height: 12px;
          }
        }

        .title {
          font-size: 20px;
        }

      }
    }

    .popbody {
      padding: 15px;
      // max-height: calc(90vh - 60px);
      height: auto;

      .productsList {
        max-height: calc(90vh - 40px);

        .product {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 10px;
          display: flex;
          border-#{$right}: 0;

          &:hover {
            background-color: white;
          }

          .productImage {
            flex: 0 0 107px;
            max-width: 107px;
            margin-#{$right}: 20px;
            margin-bottom: 0;

          }

          .productInfo {
            flex: 0 0 calc(100% - 127px);
            max-width: calc(100% - 127px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

        }
      }

      .detailsBody {
        height: auto;

        .d-flex {
          flex-direction: column;
          margin: 0;
          max-height: 100%;
          overflow: auto;

          .col-md-6 {
            flex: 0 0 auto;
            height: auto;

            img {
              width: 100%;
              height: auto;
              max-width: 100%;
              max-height: none;
            }

            &:first-child {
              flex: 0 0 100%;
              width: 100%;
              max-width: 100%;
              margin-bottom: 20px;
              padding: 0;
              // margin-top: -100px;
              flex: 0 0 auto;
              height: auto;

              img {
                border-radius: 20px 20px 0 0;
                width: auto;
                max-height: 250px;
                max-width: 90%;
              }
            }

            &:last-child {
              // flex: 0 0 calc(100% - 170px);
              // height: calc(100% - 170px);
              flex: 0 0 auto;
              height: auto;
              width: 100%;
              max-width: 100%;
              padding: 0;
              overflow: auto;
              padding-bottom: 20px;

              .infoContainer {
                height: auto;
                overflow: unset;
                padding: 0;
                margin-bottom: 80px;

                p {
                  font-size: 14px;
                }

                .documents {
                  flex-direction: column;
                  margin-bottom: 15px;
                  margin-#{$left}: 0;
                  margin-#{$right}: 0;

                  .documentWrapper {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 15px;
                    padding: 0;

                    &:last-child {
                      margin: 0
                    }
                  }
                }
              }

              .actions {
                flex-direction: column;
                align-items: flex-start;

                .quantity {
                  margin-bottom: 20px;
                }

                .cartAction {
                  width: 100%;

                  .addtocartbtn {
                    width: 100%;
                    text-align: center;
                    padding: 15px;

                  }
                }
              }
            }
          }
        }
      }

      &.smaller {
        // height: calc(100vh - 238px);
        // flex: 0 0 calc(100vh - 238px);
        height: auto;
        flex: 0 0 auto;
        // padding-bottom: 100px;
      }
    }


  }

  footer .cartToggle .trigger img,
  footer .cartToggle .trigger svg {
    width: 16px;
    height: 16px;
  }

  footer .cartToggle .trigger #dashes {
    opacity: 1;
  }

  .productsPopup .content .popbody .detailsBody .d-flex .col-md-6:last-child .actions .cartAction .updatedCartMessage {
    width: calc(100vw - 70px);
    padding: 15px;

    span {
      font-size: 14px;
    }

    .btns .primButton {
      padding: 15px;
    }
  }

  // footer,
  // header {
  //   z-index: 7;
  // }

  header .search .suggestions {
    width: 100vw;
    padding: 0 20px;
  }

  header .search.open.expanded .dynamicBackground {
    height: 500px;
  }

  header .menutoggle .menu ul li.language span,
  header .menutoggle .menu ul li.country span {
    font-size: 14px;
  }

  header .menutoggle .menu ul li.language {
    padding-bottom: 60px;
  }

  header .menutoggle .menu ul li.country {
    padding-bottom: 40px;
  }

  header .search .suggestions ul li {
    font-size: 14px;

    .path {
      font-size: 12px;
    }
  }

  // footer,
  // header {
  //   width: calc(100% - 18px);
  // }

  header {
    padding-top: 20px;

    .logo {
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  footer {
    padding-bottom: 60px;
    pointer-events: none;
  }

  header .search.open .dynamicBackground {
    width: calc(100vw - 60px);
  }

  footer .cartToggle,
  header .search,
  header .menutoggle {
    position: fixed;
    bottom: 20px;
  }

  header .menutoggle,
  footer .cartToggle {
    #{$left}: 50%;
    transform: translateX(-110%);
    pointer-events: all;
  }

  header .search {
    #{$right}: 50%;
    transform: translateX(110%);
  }

  footer .footerPlaceHolder {
    flex: 0 0 0;
    width: 0;
    min-width: 0;
    max-width: 0;
  }

  footer {
    justify-content: center;
  }

  header .menutoggle,
  footer .cartToggle {
    background-color: white;
    border-radius: 50%;
  }

  header .menutoggle .dynamicBackground,
  footer .cartToggle .dynamicBackground {
    #{$left}: auto;
    #{$right}: 0;
    bottom: -20px;
    width: 100%;
    transform: translateX(55%);
    height: 0;
    top: auto;
  }

  header .menutoggle.open .dynamicBackground,
  footer .cartToggle.open .dynamicBackground {
    width: 100vw;
    height: 80vh;
    bottom: -20px;
    #{$left}: 110%;
    transform: translateX(-50%);
    border-radius: 20px 20px 0 0;

    .head {
      padding-top: 30px;
      padding-bottom: 30px;
      // border-radius: 0 0 20px 20px;
    }
  }

  header .menutoggle {
    z-index: 1;

    .dynamicBackground {
      pointer-events: none;
    }

    &.open {

      .menu {
        height: calc(80vh - 80px);
        margin-bottom: 60px;
        padding-bottom: 0;
      }
    }
  }

  header .menutoggle .menu {
    position: absolute;
    bottom: 0;
    #{$right}: 0;
    transform: TranslateX(50%);
    width: 100vw;
    padding-bottom: 60px;
  }

  footer .cartToggle.open .trigger {
    transform: translateX(50vw) translateY(calc(-80vh + 80px));
    margin-right: 15px;

  }

  header .menutoggle a:hover #hovered,
  header .menutoggle.open .trigger #closeButton:hover #hideonHover {
    opacity: 1;
  }

  header .search {
    background-color: white;
    border-radius: 50%;

    &.raise {
      z-index: 1;
    }
  }

  header .search {
    .suggestions ul {
      list-style-type: none;
      max-height: 230px;
    }

    .dynamicBackground {
      height: 0;
      left: 0;
      right: auto;
      width: 100%;
      transform: translateX(-55%);
      bottom: -20px;
      top: auto;
    }
  }

  header .search.open {
    .searchField {
      width: calc(100vw - 30px);
    }

    .dynamicBackground {
      width: 100vw;
      transform: translateX(-51%);
      border-radius: 20px 20px 0 0;
      height: 80vh;
      padding-top: 20px;

    }
  }

  .swipeHint {
    &.hide {
      opacity: 0;
    }
  }


  header .search.open.expanded .dynamicBackground {
    height: 80vh;
  }

  .productsPopup .content {
    .pophead.align-flex-start {
      // flex: 0 0 238px;
      // height: 238px;
      flex: 0 0 202px;
      height: 202px;
    }

    .popbody {
      // flex: 0 0 calc(100% - 238px);
      // height: calc(100% - 238px);
      flex: 0 0 calc(100% - 202px);
      height: calc(100% - 202px);
    }
  }

  body::before {
    display: none;
  }

  footer .swipeHint {
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
  }


  .productsPopup {
    .content {
      .popbody {
        .productsList {
          .product {
            .productImage {
              height: auto;
            }
          }
        }
      }
    }
  }

  .search .dynamicBackground {
    >div {
      height: calc(100% - 100px);

      .suggestions {
        height: calc(100% - 100px);

        ul {
          height: 100%;
          max-height: 90%;
        }
      }
    }
  }

  .productsPopup .content .popbody .detailsBody .d-flex .col-md-6 {
    .thumbsSwiper {
      display: none;
    }

    .mySwiper {
      margin-bottom: 10px;

      .swiper-wrapper {
        padding-bottom: 10px;
      }

      .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
          background-color: #a1a1a1;
        }
      }
    }
  }

  .mapHolder .mapWrapper .pin {
    font-size: 13px;
  }


  .categoriesMenuWrapper {
    width: 42px;
    height: 42px;
    bottom: 20px;
    inset-inline-start: 15px;

    &.open {
      height: calc(100vh - 220px);
      width: calc(100% - 30px);
      border-radius: 20px;

      .categoryButton {
        opacity: 0;
        pointer-events: none;
        margin-top: -42px;
      }

      .categoriesMenu {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.selectedCategory {
      width: calc(100% - 30px);
    }

    .backbtn {
      display: block;
      border: none;
      background-color: transparent;
      text-shadow: none;
      position: relative;
      color: $primTextCol;
      top: 0;
      left: 0 !important;
      right: 0 !important;
      cursor: pointer;
      margin-bottom: 20px;

      &::before {
        filter: $primColFiler;
      }
    }

    .categoryButton {
      width: 42px;
      height: 42px;
      gap: 0;
      padding: 0;
      font-size: 0;

      &::before {
        width: 14px;
        height: 14px;
      }
    }

    .categoriesMenu {
      .menuHeader {
        padding: 15px;
        border-radius: 20px 20px 10px 10px;
        gap: 20px;

        .closeMenu {
          width: 40px;
          height: 40px;
          background-size: 12px;
        }
      }

      .menuBody {
        height: calc(100% - 120px);
        overflow: hidden;

        &.selectedCategory {
          position: relative;

          .firstLevelItems {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .secondLevelItems {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            height: 100%;
            transform: translateX(0);
            background-color: #fff;
            opacity: 1;
            position: absolute;
            top: 0;
            inset-inline-start: 0;
          }
        }

        .firstLevelItems {
          flex: 0 0 100%;
          max-width: 100%;
          border-inline-end: 1px solid rgba(221, 221, 221, 0.60);
          height: 100%;
          padding: 15px;
          min-width: 100%;
        }

        li {
          padding: 10px 0;
          gap: 15px;
          border-bottom: 1px solid #DDD;

          &::after {
            width: 7px;
            height: 14px;
            background-size: 8px;
            inset-inline-end: 5px;
            top: 50%;
            transform: translateY(-50%);
          }

          img {
            width: 30px;
            height: 30px;
          }
        }

        .secondLevelItems {
          padding: 15px;
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
          position: absolute;
          inset-inline-start: 0;
          transform: translateX(100%);
          opacity: 1;
          min-width: 100%;
        }
      }
    }

    footer .cartToggle .dynamicBackground .cartListing .items {
      max-height: 450px;
    }
  }
}



@media all and (max-height:700px) {
  .productsPopup .content .popbody .detailsBody .d-flex .col-md-6 img {
    max-width: 250px;
    max-height: 250px;
  }

  .productsPopup .content .popbody .detailsBody .d-flex .col-md-6 .infoContainer.stretched {
    height: 100%;
  }
}